<template>
  <div :class="['navigation_area', isLanding && 'landing']" v-if="device ==='desktop'">
    <div class="container">
      <nav id="navigation1" class="navigation">
        <div class="nav-header">
          <div class="nav-toggle"></div>
        </div>
        <div class="nav-menus-wrapper">
          <ul class="nav-menu nav-menu_inner align-to-right pr-1">
            <!-- <li class="home-icon" v-if="currentPage !== 'home'"><a href="/" class="px-2"><i class="fas fa-home"></i></a></li>
            <li class="domestic-button mt-1" v-if="['product', 'searchResult', 'booking'].indexOf(currentPage) === -1">
              <a :href="domesticLink" class="btn-wight">{{$t("top-header.domestic-tourism")}}</a>
            </li> -->
            <li class="dropdown" v-if="device ==='desktop'">
              <a class="dropbtn">{{$t("top-header.vacation-package")}}</a>
              <div class="dropdown-content" v-if="pageList">
                <a :href="getLink(item)" v-for="(item, inx) in pageList['FH']" :key="inx"> {{item[1].title}}</a>
              </div>
            </li>
            <li class="dropdown" v-if="device ==='desktop'">
              <a class="dropbtn">{{$t("top-header.organize-tour")}}</a>
              <div class="dropdown-content" v-if="pageList">
                <a :href="getLink(item)" v-for="(item, inx) in pageList['OT']" :key="inx"> {{item[1].title}}</a>
              </div>
            </li>
            <li class="dropdown" v-if="device ==='desktop'">
              <a class="dropbtn">{{$t("top-header.flight-only")}}</a>
              <div class="dropdown-content" v-if="pageList">
                <a :href="getLink(item)" v-for="(item, inx) in pageList['FO']" :key="inx"> {{item[1].title}}</a>
              </div>
            </li>
            <li class="dropdown" v-if="device ==='desktop'">
              <a class="dropbtn company-detail">{{$t("top-header.company-detail")}}</a>
              <div class="dropdown-content company-detail" v-if="pageList">
                <a :href="getLink(item)" v-for="(item, inx) in pageList['CD']" :key="inx"> {{item[1].title}}</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div class="menu_right">
        <!-- <div class="dropdown">
          <a href="#" class="btn-wight">{{$t("top-header.vacation-abroad")}}</a>
          <div class="dropdown-content" v-if="areaList">
            <a :href="item.link" v-for="(item, inx) in areaList" :key="inx"> {{item.title}}</a>
          </div>
        </div> -->
        <!-- <div id="st-trigger-effects" class="" v-b-toggle.nav-menu>
          <button class="btn btn-outline-secondary text-light" data-effect="st-effect-9">
            <i class="fa fa-bars"></i>
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue';
import TemplateNavigationHeader from './TemplateNavigationHeader';

export default {
  name: 'NavigationHeaderTheme0',
  mixins: [TemplateNavigationHeader],
  directives: {
    'b-toggle': VBToggle,
  },
};
</script>
<style scoped>

.navigation_area {
  background-color: rgba(255, 255, 255, 0.5);
  height: 1.8rem;
  width: 100%;
  position: absolute;
  z-index: 20;
  box-shadow: 2px 2px 10px grey;
}
.navigation_area.landing {
  position: relative;
}
.navigation_area .navigation {
  background: transparent;
  height: auto;
  width: auto;
  display: inline-block;
}
.navigation_area .navigation .nav-menu {
  margin: 0;
}
.nav-menu > li {
  display: inline-block;
  text-align: left;
}
.navigation_area .navigation .nav-menu > li > a:hover, .navigation_area .navigation .nav-menu > li.active > a {
  background-color: #66a0cd;
}
.navigation_area .navigation .nav-menu > li > a {
  height: auto;
  padding: 0.375rem 2rem;
  font-size: 1rem;
  color: rgba(var(--theme-primary));
  line-height: 18px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.3s, background 0.3s;
  vertical-align: middle;
}
.navigation_area .navigation .nav-menu > li:hover > a {
  color: #fff;
}
.navigation_area .navigation .nav-menu > li.home-icon > a {
  font-size: 1.3rem;
  padding: 13px 25px;
}
.navigation_area .navigation .nav-menu > li.domestic-button > a.btn-wight {
  /* color: #ffffff; */
  border-radius: 30px;
  padding: 8px 15px;
  font-size: 11px;
  font-weight: 400;
  text-decoration: none;
  border: 1px solid rgba(var(--theme-primary));
  margin-right: 0.5rem;
}
.navigation_area .navigation .nav-menu > li.domestic-button > a.btn-wight:hover {
  color: rgba(var(--theme-primary));
  background-color: #ffffff;
}
.navigation_area .menu_right {
  float: right;
  padding: 0.1rem 0;
}
.navigation_area .menu_right .btn-wight {
  color: #fff;
  background-color: rgba(var(--theme-primary),0.8);
  border-radius: 30px;
  padding: 0.3rem 1.2rem;
  font-size: 0.7rem;
  font-weight: 400;
  text-decoration: none;
}
.navigation_area .menu_right #st-trigger-effects {
  display: inline-flex;
  float: right;
}
.navigation_area .menu_right .btn {
  float: right;
  margin: 0 0 0 10px;
  border-color: #fff;
  padding: 1px 7px;
}
.dropdown-content.company-detail, .dropbtn.company-detail{
  transform: skew(-10deg);
}

@media (max-width: 990px) {
  .logo {
    left: 2.5rem;
  }
  .logo a img{
    width: 130px;
    margin-top: 6px;
  }
  .navigation_area{
    position: relative;
  }
}
</style>
<style>
.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: -1px 1px 2px 2px #474646;
  z-index: 200;
  min-width: max-content;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  direction: ltr;
  text-align: right;
}
.dropdown-content a {
  color: black;
  padding: 2px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {background-color: #e4f1fa;}
.dropdown:hover .dropdown-content, .dropdown .dropbtn:hover ~ .dropdown-content {display: block;}
.dropdown:hover .dropbtn {background-color: #66a0cd;}
.navigation_area .dropdown:hover:before {
  border: 8px solid transparent;
  border-bottom-color: #fff;
  bottom: 0;
  content: "";
  margin-right: -10px;
  position: absolute;
  right: 50%;
  z-index: 9999;
}
</style>
